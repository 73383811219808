<template>
<div class="container" id="page">

<h1 class="text-center">Frequently Asked Questions (FAQs)</h1>

<h3>How do I enter a prize competition?</h3>

<ol ><li><p>To enter a prize competition, you must create an account on our website (<a href="http://www.competitionmania.co.uk" >www.competitionmania.co.uk</a>). Once you have logged in, please view our active competitions and decide which prize competition you would like to enter. </p>

</li>
<li><p>Top up your wallet, which can be found in your profile section, on the left hand side as ‘’Top Up +’’. Choose the amount you would like to top up, agree to our terms and conditions, press pay using either your bank or PayPal. You will receive a purchase confirmation via email provided to sign up. </p>

</li>
<li><p>Once completed, please choose your ticket number(s) or alternatively select ‘’Lucky Dip’’ for random. Available tickets will appear in colour white (tickets unavailable for purchase are coloured as grey).</p>

</li>
</ol><p>If you wish to have a free postal entry, <a href="/terms-and-conditions#freeentry">please click here.</a></p>

<ol start="4"><li><p>Answer the qualifying skill-based question correctly. Agree to our terms and conditions. Press ‘’pay’’. Once completed, your entry will be confirmed via email used to sign up. You can also view your tickets in the ‘’My Entries’’ section on your profile.</p>

</li>
</ol><p>Please note, where the question is answered incorrectly, you will still be charged but your entry will not count. </p>


<h3>How many tickets can I purchase for each prize competition?</h3>

<p>We limit ticket purchases to a maximum of 25, however, most of our competitions are limited to 10 tickets per person. This may differ for each competition, so please view this under the number selection on each individual prize. </p>


<h3>Will I still be charged if I answer the competition question incorrectly?</h3>

<p>Yes, you will still be charged for the competition, however, your entry/entries will not count. Please ensure you answer this competition correctly to secure your entry/-ies. </p>

<h3>How long is the competition open for?</h3>

<p>The opening and closing dates of the competition are stated on the website, under each competition. If we have to change either of these dates for any reason, we will update the website accordingly. This will also be updated on our <a href="https://www.facebook.com/CompetitionManiaLtd" >Facebook page</a>). We will only change the dates if we have to for reasons out of our control. </p>

<p>Please note, competition may close early if all tickets are sold out. Again, this will be updated accordingly. </p>

<h3>Can anyone enter the competition?</h3>

<p>All our competitions are open to residents of the United Kingdom only, who are 18 years or over. We do not accept entries from anyone outside of these areas, as the laws for running competitions vary. Our competitions are organised to comply with the laws of England, Wales, Scotland and Northern Ireland. </p>

<h3>What happens if all of the tickets are not sold?</h3>

<p>No matter how many tickets are sold, the competition will still be drawn within 7 days of the closing date specified. </p>

<h3>Where can I watch the draw?</h3>

<p>All our draws will take place live on our <a href="https://www.facebook.com/CompetitionManiaLtd" >Facebook page</a>. This link can also be found on the website, under ‘’Live Draws’’.</p>

<p>Once the competition has been drawn, the link to this will be placed under ‘Live Draws’’ competition on the website (<a href="http://www.competitionmania.co.uk/livedraws" >www.competitionmania.co.uk/live-draws</a>).</p>

<h3>When does the draw take place? </h3>

<p>The competition is generally drawn within 24 hours of ticket sell-out, however, it can take up to 7 days of closing date for the live draw to take place due to reasons outside of our control. </p>

<h3>How are prize competitions drawn?</h3>

<p>All competition numbers are placed on a random number generator. This picks a number at random, which is how the winner is selected. </p>

<h3>How will I know if I have won?</h3>

<p>The winner will be notified via telephone or email within 7 days of the closing date of the competition. If you change any of your contact details prior to the closing date, you must inform us. We will try to contact you using the information you have supplied us with. If we cannot reach you within 14 days of the closing date, we reserve the right to choose another winner and you will lose the right to claim your prize.</p>

<h3>How do I receive my prize?</h3>

<p>We will either hand-deliver the prize or use DPD ®, Amazon, Hermes, Royal Mail Special Delivery Guaranteed ® delivery services to deliver all our prizes. Should the prize be too large or valuable to send by mail, we will arrange for it to be delivered personally. </p>

<h3>When will I receive my prize?</h3>

<p>Prize delivery times can vary. If you choose a physical prize, this can take up to 14 days to arrive. In competitions where a cash alternative is chosen, this will be delivered within 24 hours. </p>
<p>Are there cash alternatives for each competition?</p>

<p>This depends completely on the prize that you win. Please feel free to ask and we may offer you a cash alternative at our own discretion. </p>
<h3>How do I choose a prize?</h3>

<p>Upon winning a competition, you will be contacted via telephone or email and asked what prize you would like to have.</p>

<h3>When I top up, can I withdraw my money?</h3>

<p>Once you have topped your wallet up, you cannot remove this money from your wallet. This must be used on our competitions.</p>

<h3>How do you use my personal data? </h3>

<p>We need to use your data to administer the competitions and award prizes. We do not use your data for any other purpose. We do not share your data with any third parties unless this is necessary to administer the competition. </p>

<p>Full details on how we use your data are included in our Privacy Policy, which you can read here: <a href="http://www.competitionmania.co.uk/privacy-policy" >www.competitionmania.co.uk/privacy-policy</a> </p>

<p>If you are the winner, we may have to share your details with the Advertising Standards Authority to confirm that we have administered the competition and awarded the prizes fairly. </p>

<p>You have the right to opt out from us using your data at any time. However, if you do ask us to remove your details from our database prior to the closing date, you will be withdrawing from the competition. You will not be entitled to a refund or any entry fees you have paid. </p>

<h3>If I win, do I have to participate in promotional exercises?</h3>

<p>No, this is not compulsory. However, with your permission, we would love to share your excitement on our website and social media pages.</p>

<p>Even if you do not want to participate in any of our promotional exercises, we may have to provide your details to the Advertising Standards Authority to confirm that we have administered the competition and awarded the prizes fairly. </p>

<h3>If I win, does my name have to be publicly displayed? </h3>

<p>No, you have the right to opt out from us using your data at any time. Please contact us prior to the closing date on <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a> to request your name to be hidden from the draw. </p>

<p>How do I contact Competition Mania, if I have any questions?</p>

<p>If you have any questions, concerns or issues regarding our website, please feel free to email us on <a href="mailto:info@competitionmania.co.uk" >info@competitionmania.co.uk</a> or message on Facebook Messanger at <a href="https://www.facebook.com/CompetitionManiaLtd" >https://www.facebook.com/CompetitionManiaLtd</a></p>

<h3>What social platforms do you have?</h3>

<p>Our social media platforms currently consist of:</p>

<p>Facebook: <a href="https://www.facebook.com/CompetitionManiaLtd" >https://www.facebook.com/CompetitionManiaLtd</a></p>

<p>Instagram: <a href="https://www.instagram.com/competitionmanialtd/" >https://www.instagram.com/competitionmanialtd/</a></p>

<p>Twitter: <a href="https://twitter.com/competition_ltd" >https://twitter.com/competition_ltd</a></p>

<p>TikTok: <a href="https://www.tiktok.com/@competitionmania" >https://www.tiktok.com/@competitionmania</a>?</p>

<h3>I sent in a free entry, however, the competition has been drawn. Do I still get a free entry?</h3>

<p>Yes, your entry will be entered in a similar competition, which you will be notified of. </p>



    </div>
    <Footer/>
</template>

<script>
import Footer from '../components/global/Footer'

export default {
    components: {
        Footer
    }
}
</script>